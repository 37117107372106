export interface Iimg {
  title: string;
  dims?: string;
  img: string;
  largeImg: string;
  price?: string;
  isSold?: boolean;
}
export const imgs2024: Iimg[] = [
  {
    title: "UNDER & OVER",
    dims: "50x40cms",
    img: require("../images/2024/02.jpg"),
    largeImg: require("../images/2024/modal/02.jpg"),
    price: "£2600",
  },
  {
    title: "JUST FOR NOW",
    dims: "38x30cms",
    img: require("../images/2024/03.jpg"),
    largeImg: require("../images/2024/modal/03.jpg"),
    price: "£1650",
  },
  {
    title: "THE OTHER SIDE",
    dims: "38x30cms",
    img: require("../images/2024/04.jpg"),
    largeImg: require("../images/2024/modal/04.jpg"),
    price: "£1650",
  },
  {
    title: "LATELY REVEALED",
    dims: "50x60cms",
    img: require("../images/2024/05.jpg"),
    largeImg: require("../images/2024/modal/05.jpg"),
    price: "£3000",
  },
  {
    title: "SEACLIFF",
    dims: "50x40cms",
    img: require("../images/2024/06.jpg"),
    largeImg: require("../images/2024/modal/06.jpg"),
    price: "£2600",
  },
  {
    title: "HAMMER ROCK",
    dims: "76x38cms",
    img: require("../images/2024/07.jpg"),
    largeImg: require("../images/2024/modal/07.jpg"),
    price: "£3000",
  },
  {
    title: "BEYOND THE LINE",
    dims: "40x40cms",
    img: require("../images/2024/08.jpg"),
    largeImg: require("../images/2024/modal/08.jpg"),
    price: "£2250",
  },
  {
    title: "STRATA 1",
    dims: "30x30cms",
    img: require("../images/2024/09.jpg"),
    largeImg: require("../images/2024/modal/09.jpg"),
    price: "£1350",
  },
  {
    title: "SEAWARD HILLS",
    dims: "50x20cms",
    img: require("../images/2024/10.jpg"),
    largeImg: require("../images/2024/modal/10.jpg"),
    price: "£1350",
  },
  {
    title: "CLEAR WATERS",
    dims: "50x20cms",
    img: require("../images/2024/11.jpg"),
    largeImg: require("../images/2024/modal/11.jpg"),
    price: "£1350",
  },
  {
    title: "SEACLIFF RETURN",
    dims: "76x51cms",
    img: require("../images/2024/12.jpg"),
    largeImg: require("../images/2024/modal/12.jpg"),
    price: "£3500",
  },
  {
    title: "STRATA 2",
    dims: "30x30cms",
    img: require("../images/2024/13.jpg"),
    largeImg: require("../images/2024/modal/13.jpg"),
    price: "£1350",
  },
  {
    title: "ROCKFALL",
    dims: "40x50cms",
    img: require("../images/2024/14.jpg"),
    largeImg: require("../images/2024/modal/14.jpg"),
    price: "£2600",
  },
  {
    title: "BACK TO THE END",
    dims: "50x76cms",
    img: require("../images/2024/15.jpg"),
    largeImg: require("../images/2024/modal/15.jpg"),
    price: "£3500",
  },
  {
    title: "TILTED ROCK",
    dims: "50x40cms",
    img: require("../images/2024/16.jpg"),
    largeImg: require("../images/2024/modal/16.jpg"),
    price: "£2600",
  },
  {
    title: "ROCK SEQUENCE",
    dims: "40x40cms",
    img: require("../images/2024/17.jpg"),
    largeImg: require("../images/2024/modal/17.jpg"),
    price: "£2250",
  },
  {
    title: "FIELD EDGE",
    dims: "40x40cms",
    img: require("../images/2024/18.jpg"),
    largeImg: require("../images/2024/modal/18.jpg"),
    price: "£2250",
  },
  {
    title: "CLEARLY SEE",
    dims: "40x40cms",
    img: require("../images/2024/19.jpg"),
    largeImg: require("../images/2024/modal/19.jpg"),
    price: "£2250",
  },
  {
    title: "DOWNLAND TREES 1",
    dims: "40x40cms",
    img: require("../images/2024/20.jpg"),
    largeImg: require("../images/2024/modal/20.jpg"),
    price: "£2250",
  },
  {
    title: "DOWNLAND TREES 2",
    dims: "40x40cms",
    img: require("../images/2024/21.jpg"),
    largeImg: require("../images/2024/modal/21.jpg"),
    price: "£2250",
  },
  {
    title: "TIME TO REFLECT",
    dims: "50x50cms",
    img: require("../images/2024/22.jpg"),
    largeImg: require("../images/2024/modal/22.jpg"),
    price: "£2800",
  },
  {
    title: "FRACTURED ELEVATION",
    dims: "76x27cms",
    img: require("../images/2024/23.jpg"),
    largeImg: require("../images/2024/modal/23.jpg"),
    price: "£2700",
  },
  {
    title: "BENEATH & BEYOND",
    dims: "50x40cms",
    img: require("../images/2024/24.jpg"),
    largeImg: require("../images/2024/modal/24.jpg"),
    price: "£2600",
  },
  {
    title: "CHALKFALL",
    dims: "40x40cms",
    img: require("../images/2024/25.jpg"),
    largeImg: require("../images/2024/modal/25.jpg"),
    price: "£2250",
  },
  {
    title: "LESS TRAVELLED",
    dims: "40x40cms",
    img: require("../images/2024/26.jpg"),
    largeImg: require("../images/2024/modal/26.jpg"),
    price: "£2250",
  },
  {
    title: "PASSING BY",
    dims: "40x40cms",
    img: require("../images/2024/27.jpg"),
    largeImg: require("../images/2024/modal/27.jpg"),
    price: "£2250",
  },
  {
    title: "SALTPILL ESTUARY",
    dims: "40x40cms",
    img: require("../images/2024/28.jpg"),
    largeImg: require("../images/2024/modal/28.jpg"),
    price: "£2250",
  },
  {
    title: "HIGH STACK",
    dims: "30x30cms",
    img: require("../images/2024/29.jpg"),
    largeImg: require("../images/2024/modal/29.jpg"),
    price: "£1350",
  },
  {
    title: "ALWAYS SO NEAR",
    dims: "52x50cms",
    img: require("../images/2024/30.jpg"),
    largeImg: require("../images/2024/modal/30.jpg"),
    price: "£2900",
  },
  {
    title: "LIGHTWATER",
    dims: "32x40cms",
    img: require("../images/2024/01.jpg"),
    largeImg: require("../images/2024/modal/01.jpg"),
    price: "£1800",
    isSold: true,
  },
];

export const imgs2019: Iimg[] = [
  {
    title: "Darkwater",
    dims: "40x40cms",
    img: require("../images/2019/15.jpg"),
    largeImg: require("../images/2019/modal/15.jpg"),
  },
  {
    title: "End Elevation",
    dims: "48x30cm",
    img: require("../images/2019/1.jpg"),
    largeImg: require("../images/2019/modal/1.jpg"),
  },
  {
    title: "Small Sanctuary",
    dims: "40x40cms",
    img: require("../images/2019/2.jpg"),
    largeImg: require("../images/2019/modal/2.jpg"),
  },
  {
    title: "Coastal Division (Red)",
    dims: "40x50cms",
    img: require("../images/2019/3.jpg"),
    largeImg: require("../images/2019/modal/3.jpg"),
  },
  {
    title: "Seacliff Return",
    dims: "76x50cm",
    img: require("../images/2019/4.jpg"),
    largeImg: require("../images/2019/modal/4.jpg"),
  },
  {
    title: "Seacliff 2",
    dims: "50x40cm",
    img: require("../images/2019/5.jpg"),
    largeImg: require("../images/2019/modal/5.jpg"),
  },
  {
    title: "Rockfall",
    dims: "40x50cm",
    img: require("../images/2019/6.jpg"),
    largeImg: require("../images/2019/modal/6.jpg"),
  },
  {
    title: "Winged Wire",
    dims: "50x40cm",
    img: require("../images/2019/7.jpg"),
    largeImg: require("../images/2019/modal/7.jpg"),
  },
  {
    title: "West Wing",
    dims: "50x60cms",
    img: require("../images/2019/8.jpg"),
    largeImg: require("../images/2019/modal/8.jpg"),
  },
  {
    title: "Beyond the line",
    dims: "40x40cms",
    img: require("../images/2019/9.jpg"),
    largeImg: require("../images/2019/modal/9.jpg"),
  },
  {
    title: "Beneath The Surface",
    dims: "50x76cm",
    img: require("../images/2019/10.jpg"),
    largeImg: require("../images/2019/modal/10.jpg"),
  },
  {
    title: "Northcott Edge",
    dims: "48x30cm",
    img: require("../images/2019/11.jpg"),
    largeImg: require("../images/2019/modal/11.jpg"),
  },
  {
    title: "Clearly See",
    dims: "29x57cm",
    img: require("../images/2019/12.jpg"),
    largeImg: require("../images/2019/modal/12.jpg"),
  },
  {
    title: "Stonescape 2",
    dims: "29x57cm",
    img: require("../images/2019/13.jpg"),
    largeImg: require("../images/2019/modal/13.jpg"),
  },
  {
    title: "C-Rock",
    dims: "40x40cm",
    img: require("../images/2019/14.jpg"),
    largeImg: require("../images/2019/modal/14.jpg"),
  },
  {
    title: "Clearview",
    dims: "50x76cm",
    img: require("../images/2019/16.jpg"),
    largeImg: require("../images/2019/modal/16.jpg"),
  },
  {
    title: "Hammer Rock 1",
    dims: "76x38cm",
    img: require("../images/2019/17.jpg"),
    largeImg: require("../images/2019/modal/17.jpg"),
  },
  {
    title: "Hammer Rock 2",
    dims: "76x38cm",
    img: require("../images/2019/18.jpg"),
    largeImg: require("../images/2019/modal/18.jpg"),
  },
  {
    title: "High Water",
    dims: "76x50cm",
    img: require("../images/2019/19.jpg"),
    largeImg: require("../images/2019/modal/19.jpg"),
  },
  {
    title: "Changing Places",
    dims: "76x38cms",
    img: require("../images/2019/20.jpg"),
    largeImg: require("../images/2019/modal/20.jpg"),
  },
  {
    title: "Lately Revealed",
    dims: "50x60cm",
    img: require("../images/2019/21.jpg"),
    largeImg: require("../images/2019/modal/21.jpg"),
  },
  {
    title: "Wirework",
    dims: "40x40cm",
    img: require("../images/2019/22.jpg"),
    largeImg: require("../images/2019/modal/22.jpg"),
  },
  {
    title: "Stone Alone",
    dims: "24x50cm",
    img: require("../images/2019/23.jpg"),
    largeImg: require("../images/2019/modal/23.jpg"),
  },
  {
    title: "Up There",
    dims: "50x20cm",
    img: require("../images/2019/24.jpg"),
    largeImg: require("../images/2019/modal/24.jpg"),
  },
  {
    title: "Split Elevation",
    dims: "50x60cm",
    img: require("../images/2019/25.jpg"),
    largeImg: require("../images/2019/modal/25.jpg"),
  },
  {
    title: "High Light",
    dims: "50x50cm",
    img: require("../images/2019/26.jpg"),
    largeImg: require("../images/2019/modal/26.jpg"),
  },
  {
    title: "Around Now",
    dims: "20x50cm",
    img: require("../images/2019/27.jpg"),
    largeImg: require("../images/2019/modal/27.jpg"),
  },
  {
    title: "Time To Reflect",
    dims: "50x50cm",
    img: require("../images/2019/28.jpg"),
    largeImg: require("../images/2019/modal/28.jpg"),
  },
  {
    title: "Freefall",
    dims: "50x50cm",
    img: require("../images/2019/29.jpg"),
    largeImg: require("../images/2019/modal/29.jpg"),
  },
  {
    title: "Edge Return",
    dims: "23x38cm",
    img: require("../images/2019/30.jpg"),
    largeImg: require("../images/2019/modal/30.jpg"),
  },
  {
    title: "Fracture Detail",
    dims: "40x40cm",
    img: require("../images/2019/31.jpg"),
    largeImg: require("../images/2019/modal/31.jpg"),
  },
  {
    title: "Stonework",
    dims: "30x30cm",
    img: require("../images/2019/32.jpg"),
    largeImg: require("../images/2019/modal/32.jpg"),
  },
  {
    title: "Drawing Near",
    dims: "27x38cm",
    img: require("../images/2019/33.jpg"),
    largeImg: require("../images/2019/modal/33.jpg"),
  },
  {
    title: "End Fracture",
    dims: "30x30cm",
    img: require("../images/2019/34.jpg"),
    largeImg: require("../images/2019/modal/34.jpg"),
  },
  {
    title: "Strata 1",
    dims: "30x30cm",
    img: require("../images/2019/35.jpg"),
    largeImg: require("../images/2019/modal/35.jpg"),
  },
  {
    title: "Greenstack",
    dims: "50x35cm",
    img: require("../images/2019/36.jpg"),
    largeImg: require("../images/2019/modal/36.jpg"),
  },
  {
    title: "Stone Edge 2",
    dims: "50x29cm",
    img: require("../images/2019/37.jpg"),
    largeImg: require("../images/2019/modal/37.jpg"),
  },
  {
    title: "Strata 2",
    dims: "30x30cm",
    img: require("../images/2019/38.jpg"),
    largeImg: require("../images/2019/modal/38.jpg"),
  },
  {
    title: "High Stack",
    dims: "30x30cm",
    img: require("../images/2019/39.jpg"),
    largeImg: require("../images/2019/modal/39.jpg"),
  },
  {
    title: "mall Divisions",
    dims: "28x40cm",
    img: require("../images/2019/40.jpg"),
    largeImg: require("../images/2019/modal/40.jpg"),
  },
  {
    title: "Lightwater",
    dims: "40x32cm",
    img: require("../images/2019/41.jpg"),
    largeImg: require("../images/2019/modal/41.jpg"),
  },
  {
    title: "Ebb-Line Detail",
    dims: "32x60cm",
    img: require("../images/2019/42.jpg"),
    largeImg: require("../images/2019/modal/42.jpg"),
  },
  {
    title: "Blue Stack",
    dims: "51x32cm",
    img: require("../images/2019/43.jpg"),
    largeImg: require("../images/2019/modal/43.jpg"),
  },
  {
    title: "Lately So Near",
    dims: "50x50cm",
    img: require("../images/2019/44.jpg"),
    largeImg: require("../images/2019/modal/44.jpg"),
  },
  {
    title: "Greencliff Return",
    dims: "30x30cm",
    img: require("../images/2019/45.jpg"),
    largeImg: require("../images/2019/modal/45.jpg"),
  },
  {
    title: "C-Rock-2",
    dims: "80x140cm",
    img: require("../images/2019/46.jpg"),
    largeImg: require("../images/2019/modal/46.jpg"),
  },
];

export const imgsApr08: Iimg[] = [
  {
    title: "Rockfall",
    img: require("../images/other/Apr08/15-Rockfall.jpg"),
    largeImg: require("../images/other/Apr08/15-Rockfall.jpg"),
  },
  {
    title: "Turning Point",
    img: require("../images/other/Apr08/14-Turning-Point.jpg"),
    largeImg: require("../images/other/Apr08/14-Turning-Point.jpg"),
  },
  {
    title: "Separation",
    img: require("../images/other/Apr08/13-Separation.jpg"),
    largeImg: require("../images/other/Apr08/13-Separation.jpg"),
  },
  {
    title: "Fracture",
    img: require("../images/other/Apr08/12-Fracture.jpg"),
    largeImg: require("../images/other/Apr08/12-Fracture.jpg"),
  },
  {
    title: "Flintwork",
    img: require("../images/other/Apr08/11-Flintwork.jpg"),
    largeImg: require("../images/other/Apr08/11-Flintwork.jpg"),
  },
  {
    title: "Chalkstack",
    img: require("../images/other/Apr08/10-Chalkstack.jpg"),
    largeImg: require("../images/other/Apr08/10-Chalkstack.jpg"),
  },
  {
    title: "Landwork",
    img: require("../images/other/Apr08/09-Landwork.jpg"),
    largeImg: require("../images/other/Apr08/09-Landwork.jpg"),
  },
  {
    title: "Chalkfield",
    img: require("../images/other/Apr08/08-Chalkfield.jpg"),
    largeImg: require("../images/other/Apr08/08-Chalkfield.jpg"),
  },
  {
    title: "Stonework",
    img: require("../images/other/Apr08/07-Stonework.jpg"),
    largeImg: require("../images/other/Apr08/07-Stonework.jpg"),
  },
  {
    title: "Turning Circle",
    img: require("../images/other/Apr08/06-Turning-Circle.jpg"),
    largeImg: require("../images/other/Apr08/06-Turning-Circle.jpg"),
  },
  {
    title: "Unsettled Edge",
    img: require("../images/other/Apr08/05--Unsettled-Edge.jpg"),
    largeImg: require("../images/other/Apr08/05--Unsettled-Edge.jpg"),
  },
  {
    title: "White Edge",
    img: require("../images/other/Apr08/04-White-Edge.jpg"),
    largeImg: require("../images/other/Apr08/04-White-Edge.jpg"),
  },
  {
    title: "Headland",
    img: require("../images/other/Apr08/03-Headland.jpg"),
    largeImg: require("../images/other/Apr08/03-Headland.jpg"),
  },
  {
    title: "Overview",
    img: require("../images/other/Apr08/02-Overview.jpg"),
    largeImg: require("../images/other/Apr08/02-Overview.jpg"),
  },
  {
    title: "Downfall",
    img: require("../images/other/Apr08/01-Downfall.jpg"),
    largeImg: require("../images/other/Apr08/01-Downfall.jpg"),
  },
];
export const imgsPrev: Iimg[] = [
  {
    title: "Where I Stand",
    img: require("../images/other/WhereIStand.jpg"),
    largeImg: require("../images/other/WhereIStand.jpg"),
  },
  {
    title: "Rockstack 2",
    img: require("../images/other/Rockstack2.jpg"),
    largeImg: require("../images/other/Rockstack2.jpg"),
  },
  {
    title: "Point of View",
    img: require("../images/other/PointofView.jpg"),
    largeImg: require("../images/other/PointofView.jpg"),
  },
  {
    title: "Outland",
    img: require("../images/other/Outland.jpg"),
    largeImg: require("../images/other/Outland.jpg"),
  },
  {
    title: "Northcott Return",
    img: require("../images/other/NorthcottReturn.jpg"),
    largeImg: require("../images/other/NorthcottReturn.jpg"),
  },
  {
    title: "Northcott 2",
    img: require("../images/other/Northcott2.jpg"),
    largeImg: require("../images/other/Northcott2.jpg"),
  },
  {
    title: "Miles Ahead",
    img: require("../images/other/MilesAhead.jpg"),
    largeImg: require("../images/other/MilesAhead.jpg"),
  },
  {
    title: "Linework",
    img: require("../images/other/Linework.jpg"),
    largeImg: require("../images/other/Linework.jpg"),
  },
  {
    title: "Landwork",
    img: require("../images/other/Landwork.jpg"),
    largeImg: require("../images/other/Landwork.jpg"),
  },
  {
    title: "Landmark 2",
    img: require("../images/other/Landmark2.jpg"),
    largeImg: require("../images/other/Landmark2.jpg"),
  },
  {
    title: "Landmark 1",
    img: require("../images/other/Landmark1.jpg"),
    largeImg: require("../images/other/Landmark1.jpg"),
  },
  {
    title: "Landform 3",
    img: require("../images/other/Landform3.jpg"),
    largeImg: require("../images/other/Landform3.jpg"),
  },
  {
    title: "Field of Vision",
    img: require("../images/other/FieldOfVision.jpg"),
    largeImg: require("../images/other/FieldOfVision.jpg"),
  },
  {
    title: "Enclosed Landform",
    img: require("../images/other/EnclosedLandform.jpg"),
    largeImg: require("../images/other/EnclosedLandform.jpg"),
  },
  {
    title: "Clearly See",
    img: require("../images/other/ClearlySee.jpg"),
    largeImg: require("../images/other/ClearlySee.jpg"),
  },
  {
    title: "Chalkstack 2",
    img: require("../images/other/Chalkstack2.jpg"),
    largeImg: require("../images/other/Chalkstack2.jpg"),
  },
  {
    title: "Cave Division",
    img: require("../images/other/CaveDivision.jpg"),
    largeImg: require("../images/other/CaveDivision.jpg"),
  },
  {
    title: "Being There",
    img: require("../images/other/BeingThere.jpg"),
    largeImg: require("../images/other/BeingThere.jpg"),
  },
  {
    title: "Altered Image",
    img: require("../images/other/AlteredImage.jpg"),
    largeImg: require("../images/other/AlteredImage.jpg"),
  },
];

export const imgsAug07: Iimg[] = [
  {
    title: "",
    img: require("../images/other/Aug07/1.jpg"),
    largeImg: require("../images/other/Aug07/1.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Aug07/2.jpg"),
    largeImg: require("../images/other/Aug07/2.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Aug07/3.jpg"),
    largeImg: require("../images/other/Aug07/3.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Aug07/4.jpg"),
    largeImg: require("../images/other/Aug07/4.jpg"),
  },
];

export const imgsCranborne2013: Iimg[] = [
  {
    title: "",
    img: require("../images/other/Cranborne2013/19_From_Sovell_Down_38x38cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/19_From_Sovell_Down_38x38cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/18_By_Gussage_All_Saints_50x50cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/18_By_Gussage_All_Saints_50x50cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/16_By_Gussage_St_Michael_59x36cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/16_By_Gussage_St_Michael_59x36cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/17_Barn_By_Ackling_Dyke_38x91cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/17_Barn_By_Ackling_Dyke_38x91cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/15_Gussage_Down_2_76x28cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/15_Gussage_Down_2_76x28cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/14_Gussage_Down_50x38cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/14_Gussage_Down_50x38cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/10_S&D_Sleeper_Post_38x23cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/10_S&D_Sleeper_Post_38x23cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/11_Towards_Down_Farm_28x54cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/11_Towards_Down_Farm_28x54cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/12_Towards_South_Down_25x51cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/12_Towards_South_Down_25x51cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/13_Gussage_Hill_54x23cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/13_Gussage_Hill_54x23cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/8_Harley_Down_2_30x60cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/8_Harley_Down_2_30x60cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/9_Earthwork&Post_22x51cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/9_Earthwork&Post_22x51cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/7_Harley_Down_29x57cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/7_Harley_Down_29x57cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/6_Towards_Pentridge_2_76x36cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/6_Towards_Pentridge_2_76x36cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/5_Towards_Pentridge_40x50cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/5_Towards_Pentridge_40x50cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/4_Avenue_Tenantry_Down_50x60cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/4_Avenue_Tenantry_Down_50x60cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/3_Knowlton_2_50x38cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/3_Knowlton_2_50x38cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/2_Knowlton_50x38cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/2_Knowlton_50x38cms_300dpi.jpg"),
  },
  {
    title: "",
    img: require("../images/other/Cranborne2013/1_Knowlton_Rings_20x20cms_300dpi.jpg"),
    largeImg: require("../images/other/Cranborne2013/1_Knowlton_Rings_20x20cms_300dpi.jpg"),
  },
];
export const imgsOutOfTheBlue2013: Iimg[] = [
  {
    title: "Seacliff 2",
    dims: "23x20cms",
    img: require("../images/other/OutOfTheBlue2013/10_Seacliff2_23x56cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/10_Seacliff2_23x56cms_300dpi.jpg"),
  },
  {
    title: "Northcott Return",
    dims: "60x60cms",
    img: require("../images/other/OutOfTheBlue2013/9_Northcott_Return_60x60cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/9_Northcott_Return_60x60cms_300dpi.jpg"),
  },
  {
    title: "Beneath the Surface",
    dims: "50x76cms",
    img: require("../images/other/OutOfTheBlue2013/8_Beneath_The_Surface_50x76cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/8_Beneath_The_Surface_50x76cms_300dpi.jpg"),
  },
  {
    title: "On That Day",
    dims: "90x60cms",
    img: require("../images/other/OutOfTheBlue2013/7_On_That_Day_90x60cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/7_On_That_Day_90x60cms_300dpi.jpg"),
  },
  {
    title: "Scene from Afar",
    dims: "60x60cms",
    img: require("../images/other/OutOfTheBlue2013/6_Scene_From_Afar_60x60cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/6_Scene_From_Afar_60x60cms_300dpi.jpg"),
  },
  {
    title: "Coming Back",
    dims: "76x51cms",
    img: require("../images/other/OutOfTheBlue2013/5_Coming_Back_76x51cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/5_Coming_Back_76x51cms_300dpi.jpg"),
  },
  {
    title: "Out of the Blue",
    dims: "76x51cms",
    img: require("../images/other/OutOfTheBlue2013/4_Out_Of_The_Blue_76x51cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/4_Out_Of_The_Blue_76x51cms_300dpi.jpg"),
  },
  {
    title: "Downland Edge",
    dims: "60x120cms",
    img: require("../images/other/OutOfTheBlue2013/3_Downland_Edge_60x120cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/3_Downland_Edge_60x120cms_300dpi.jpg"),
  },
  {
    title: "Viewpoint 2",
    dims: "46x40cms",
    img: require("../images/other/OutOfTheBlue2013/2_Viewpoint2_46x40cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/2_Viewpoint2_46x40cms_300dpi.jpg"),
  },
  {
    title: "Lyme Detail",
    dims: "25x38cms",
    img: require("../images/other/OutOfTheBlue2013/1_Lyme_Detail_25x38cms_300dpi.jpg"),
    largeImg: require("../images/other/OutOfTheBlue2013/1_Lyme_Detail_25x38cms_300dpi.jpg"),
  },
];

export const imgsStones2013: Iimg[] = [
  {
    title: "Green cliff Return",
    dims: "30x30cms",
    img: require("../images/other/Stones2013/16_Greencliff_Return_30x30cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/16_Greencliff_Return_30x30cms_300dpi.jpg"),
  },
  {
    title: "Paesina Stone 1",
    dims: "40x40cms",
    img: require("../images/other/Stones2013/1_Paesina_Stone1_40x40cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/1_Paesina_Stone1_40x40cms_300dpi.jpg"),
  },
  {
    title: "Paesina Stone 2",
    dims: "40x40cms",
    img: require("../images/other/Stones2013/2_Paesina_Stone2_40x40cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/2_Paesina_Stone2_40x40cms_300dpi.jpg"),
  },
  {
    title: "Transition 1",
    dims: "40x40cms",
    img: require("../images/other/Stones2013/3_Transition1_40x40cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/3_Transition1_40x40cms_300dpi.jpg"),
  },
  {
    title: "Transition 2",
    dims: "50x40cms",
    img: require("../images/other/Stones2013/4_Transition2_50x40cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/4_Transition2_50x40cms_300dpi.jpg"),
  },
  {
    title: "Towards Purbeck 1",
    dims: "50x40cms",
    img: require("../images/other/Stones2013/5_Towards_Purbeck1_50x40cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/5_Towards_Purbeck1_50x40cms_300dpi.jpg"),
  },
  {
    title: "Towards Purbeck 2",
    dims: "43x33cms",
    img: require("../images/other/Stones2013/6_Towards_Purbeck2_43x33cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/6_Towards_Purbeck2_43x33cms_300dpi.jpg"),
  },
  {
    title: "Triptych 1",
    dims: "30x90cms",
    img: require("../images/other/Stones2013/7_Triptych1_30x90cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/7_Triptych1_30x90cms_300dpi.jpg"),
  },
  {
    title: "Triptych 2",
    dims: "30x90cms",
    img: require("../images/other/Stones2013/8_Triptych2_30x90cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/8_Triptych2_30x90cms_300dpi.jpg"),
  },
  {
    title: "Interpretation 1",
    dims: "40x40cms",
    img: require("../images/other/Stones2013/9_interpretation1_40x40cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/9_interpretation1_40x40cms_300dpi.jpg"),
  },
  {
    title: "Interpretation 2",
    dims: "30x30cms",
    img: require("../images/other/Stones2013/10_Interpretation2_30x30cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/10_Interpretation2_30x30cms_300dpi.jpg"),
  },
  {
    title: "Resolved Image",
    dims: "60x60cms",
    img: require("../images/other/Stones2013/11_Resolved_Image_60x60cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/11_Resolved_Image_60x60cms_300dpi.jpg"),
  },
  {
    title: "Paesina Detail",
    dims: "60x30cms",
    img: require("../images/other/Stones2013/12_Paesina_Detail_60x30cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/12_Paesina_Detail_60x30cms_300dpi.jpg"),
  },
  {
    title: "Lateral Detail",
    dims: "24x60cms",
    img: require("../images/other/Stones2013/13_Lateral_Detail_24x60cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/13_Lateral_Detail_24x60cms_300dpi.jpg"),
  },
  {
    title: "Stonescape",
    dims: "29x57cms",
    img: require("../images/other/Stones2013/14_Stonescape_29x57cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/14_Stonescape_29x57cms_300dpi.jpg"),
  },
  {
    title: "Red Elevation",
    dims: "48x30cms",
    img: require("../images/other/Stones2013/15_Red_Elevation_48x30cms_300dpi.jpg"),
    largeImg: require("../images/other/Stones2013/15_Red_Elevation_48x30cms_300dpi.jpg"),
  },
];
export const imgsJan2011: Iimg[] = [
  {
    title: "Where I Stand",
    dims: "",
    img: require("../images/other/Jan2011/WhereIStand.jpg"),
    largeImg: require("../images/other/Jan2011/WhereIStand.jpg"),
  },
  {
    title: "Point of View",
    dims: "",
    img: require("../images/other/Jan2011/PointofView.jpg"),
    largeImg: require("../images/other/Jan2011/PointofView.jpg"),
  },
  {
    title: "Rockstack 2",
    dims: "",
    img: require("../images/other/Jan2011/Rockstack2.jpg"),
    largeImg: require("../images/other/Jan2011/Rockstack2.jpg"),
  },
  {
    title: "Northcott Return",
    dims: "",
    img: require("../images/other/Jan2011/NorthcottReturn.jpg"),
    largeImg: require("../images/other/Jan2011/NorthcottReturn.jpg"),
  },
  {
    title: "Outland",
    dims: "",
    img: require("../images/other/Jan2011/Outland.jpg"),
    largeImg: require("../images/other/Jan2011/Outland.jpg"),
  },
  {
    title: "Outlook",
    dims: "",
    img: require("../images/other/Jan2011/Outlook.jpg"),
    largeImg: require("../images/other/Jan2011/Outlook.jpg"),
  },
  {
    title: "Northcott 2",
    dims: "",
    img: require("../images/other/Jan2011/Northcott2.jpg"),
    largeImg: require("../images/other/Jan2011/Northcott2.jpg"),
  },
  {
    title: "Linework",
    dims: "",
    img: require("../images/other/Jan2011/Linework.jpg"),
    largeImg: require("../images/other/Jan2011/Linework.jpg"),
  },
  {
    title: "Field of Vision",
    dims: "",
    img: require("../images/other/Jan2011/FieldofVision.jpg"),
    largeImg: require("../images/other/Jan2011/FieldofVision.jpg"),
  },
  {
    title: "Landform",
    dims: "",
    img: require("../images/other/Jan2011/Landform.jpg"),
    largeImg: require("../images/other/Jan2011/Landform.jpg"),
  },
  {
    title: "Landmark1.jpg",
    dims: "",
    img: require("../images/other/Jan2011/Landmark1.jpg"),
    largeImg: require("../images/other/Jan2011/Landmark1.jpg"),
  },
  {
    title: "Enclosed Landform",
    dims: "",
    img: require("../images/other/Jan2011/EnclosedLandform.jpg"),
    largeImg: require("../images/other/Jan2011/EnclosedLandform.jpg"),
  },
  {
    title: "Clearly See",
    dims: "",
    img: require("../images/other/Jan2011/ClearlySee.jpg"),
    largeImg: require("../images/other/Jan2011/ClearlySee.jpg"),
  },
  {
    title: "Coastal Division",
    dims: "",
    img: require("../images/other/Jan2011/CoastalDivision.jpg"),
    largeImg: require("../images/other/Jan2011/CoastalDivision.jpg"),
  },
  {
    title: "Chalkstack 2",
    dims: "",
    img: require("../images/other/Jan2011/Chalkstack2.jpg"),
    largeImg: require("../images/other/Jan2011/Chalkstack2.jpg"),
  },
  {
    title: "Cave Division",
    dims: "",
    img: require("../images/other/Jan2011/CaveDivision.jpg"),
    largeImg: require("../images/other/Jan2011/CaveDivision.jpg"),
  },
  {
    title: "Being There",
    dims: "",
    img: require("../images/other/Jan2011/BeingThere.jpg"),
    largeImg: require("../images/other/Jan2011/BeingThere.jpg"),
  },
  {
    title: "Altered Image",
    dims: "",
    img: require("../images/other/Jan2011/AlteredImage.jpg"),
    largeImg: require("../images/other/Jan2011/AlteredImage.jpg"),
  },
  {
    title: "View Point",
    dims: "",
    img: require("../images/other/Jan2011/viewPoint.jpg"),
    largeImg: require("../images/other/Jan2011/viewPoint.jpg"),
  },
  {
    title: "Now Again",
    dims: "",
    img: require("../images/other/Jan2011/nowAgain.jpg"),
    largeImg: require("../images/other/Jan2011/nowAgain.jpg"),
  },
  {
    title: "Stone Edge 2",
    dims: "",
    img: require("../images/other/Jan2011/stoneEdge2.jpg"),
    largeImg: require("../images/other/Jan2011/stoneEdge2.jpg"),
  },
  {
    title: "Suspended Stone",
    dims: "",
    img: require("../images/other/Jan2011/suspendedStone.jpg"),
    largeImg: require("../images/other/Jan2011/suspendedStone.jpg"),
  },
  {
    title: "Hidden Wire",
    dims: "",
    img: require("../images/other/Jan2011/hiddenWire.jpg"),
    largeImg: require("../images/other/Jan2011/hiddenWire.jpg"),
  },
  {
    title: "Extended Edge 2",
    dims: "",
    img: require("../images/other/Jan2011/extendedEdge2.jpg"),
    largeImg: require("../images/other/Jan2011/extendedEdge2.jpg"),
  },
  {
    title: "Extended Arc",
    dims: "",
    img: require("../images/other/Jan2011/extendedArc.jpg"),
    largeImg: require("../images/other/Jan2011/extendedArc.jpg"),
  },
  {
    title: "Chalkfield Return",
    dims: "",
    img: require("../images/other/Jan2011/chalkfieldReturn.jpg"),
    largeImg: require("../images/other/Jan2011/chalkfieldReturn.jpg"),
  },
  {
    title: "Chase Return",
    dims: "",
    img: require("../images/other/Jan2011/chaseReturn.jpg"),
    largeImg: require("../images/other/Jan2011/chaseReturn.jpg"),
  },
  {
    title: "Chalk Elevation 3",
    dims: "",
    img: require("../images/other/Jan2011/chalkElevation3.jpg"),
    largeImg: require("../images/other/Jan2011/chalkElevation3.jpg"),
  },
  {
    title: "Beyond the Edge",
    dims: "",
    img: require("../images/other/Jan2011/beyondTheEdge.jpg"),
    largeImg: require("../images/other/Jan2011/beyondTheEdge.jpg"),
  },
  {
    title: "Beneath the Wire",
    dims: "",
    img: require("../images/other/Jan2011/beneathTheWire.jpg"),
    largeImg: require("../images/other/Jan2011/beneathTheWire.jpg"),
  },
  {
    title: "Below the Line",
    dims: "",
    img: require("../images/other/Jan2011/belowTheLine.jpg"),
    largeImg: require("../images/other/Jan2011/belowTheLine.jpg"),
  },
];

export const imgsEarlier: Iimg[] = [
  {
    title: "Sanctuary",
    dims: "60x50cm",
    img: require("../images/other/Earlier/1_Sanctuary_60x50cm.jpg"),
    largeImg: require("../images/other/Earlier/1_Sanctuary_60x50cm.jpg"),
  },
  {
    title: "Silent Working",
    dims: "70x70cm",
    img: require("../images/other/Earlier/2_Silent_Working_70x70cm.jpg"),
    largeImg: require("../images/other/Earlier/2_Silent_Working_70x70cm.jpg"),
  },
  {
    title: "Landwork 2",
    dims: "53x53cm",
    img: require("../images/other/Earlier/3_Landwork_2_53x53cm.jpg"),
    largeImg: require("../images/other/Earlier/3_Landwork_2_53x53cm.jpg"),
  },
  {
    title: "Beyond the Edge",
    dims: "60x60cm",
    img: require("../images/other/Earlier/5_Beyond_The_Edge_60x60cm.jpg"),
    largeImg: require("../images/other/Earlier/5_Beyond_The_Edge_60x60cm.jpg"),
  },
  {
    title: "Overview 2",
    dims: "60x60cm",
    img: require("../images/other/Earlier/6_Overview_2_60x60cm.jpg"),
    largeImg: require("../images/other/Earlier/6_Overview_2_60x60cm.jpg"),
  },
  {
    title: "Coastal Return",
    dims: "36x80cm",
    img: require("../images/other/Earlier/7_Coastal_Return_36x80cm.jpg"),
    largeImg: require("../images/other/Earlier/7_Coastal_Return_36x80cm.jpg"),
  },
  {
    title: "Stone Suspension",
    dims: "46x60cm",
    img: require("../images/other/Earlier/8_Stone_Suspension_46x60cm.jpg"),
    largeImg: require("../images/other/Earlier/8_Stone_Suspension_46x60cm.jpg"),
  },
  {
    title: "Chalk Division",
    dims: "60x60cm",
    img: require("../images/other/Earlier/9_Chalk_Division_60x60cm.jpg"),
    largeImg: require("../images/other/Earlier/9_Chalk_Division_60x60cm.jpg"),
  },
  {
    title: "Recolection 2",
    dims: "92x30cm",
    img: require("../images/other/Earlier/10_Recolection_2_92x30cm.jpg"),
    largeImg: require("../images/other/Earlier/10_Recolection_2_92x30cm.jpg"),
  },
  {
    title: "Turning Point",
    dims: "46x60cm",
    img: require("../images/other/Earlier/11_Turning_Point_46x60cm.jpg"),
    largeImg: require("../images/other/Earlier/11_Turning_Point_46x60cm.jpg"),
  },
  {
    title: "White Edge",
    dims: "30x60cm",
    img: require("../images/other/Earlier/12_White_Edge_30x60cm.jpg"),
    largeImg: require("../images/other/Earlier/12_White_Edge_30x60cm.jpg"),
  },
  {
    title: "Cave",
    dims: "23x28cm",
    img: require("../images/other/Earlier/13_Cave_23x28cm.jpg"),
    largeImg: require("../images/other/Earlier/13_Cave_23x28cm.jpg"),
  },
  {
    title: "Unsettled Surface",
    dims: "40x41cm",
    img: require("../images/other/Earlier/15_Unsettled_Surface_40x41cm.jpg"),
    largeImg: require("../images/other/Earlier/15_Unsettled_Surface_40x41cm.jpg"),
  },
  {
    title: "Recollection 1",
    dims: "102x38cm",
    img: require("../images/other/Earlier/16_Recollection_1_102x38cm.jpg"),
    largeImg: require("../images/other/Earlier/16_Recollection_1_102x38cm.jpg"),
  },
  {
    title: "Earth Working",
    dims: "80x50cm",
    img: require("../images/other/Earlier/17_Earthworking_80x50cm.jpg"),
    largeImg: require("../images/other/Earlier/17_Earthworking_80x50cm.jpg"),
  },
  {
    title: "Dark Edge",
    dims: "39x42cm",
    img: require("../images/other/Earlier/18_Dark_Edge_39x42cm.jpg"),
    largeImg: require("../images/other/Earlier/18_Dark_Edge_39x42cm.jpg"),
  },
  {
    title: "Rockpool",
    dims: "46x46cm",
    img: require("../images/other/Earlier/19_Rockpool_46x46cm.jpg"),
    largeImg: require("../images/other/Earlier/19_Rockpool_46x46cm.jpg"),
  },
  {
    title: "Shortfall",
    dims: "102x36cm",
    img: require("../images/other/Earlier/20_Shortfall_102x36cm.jpg"),
    largeImg: require("../images/other/Earlier/20_Shortfall_102x36cm.jpg"),
  },
  {
    title: "Beneath The Surface",
    dims: "92x61cm",
    img: require("../images/other/Earlier/21_Beneath_The_Surface_92x61cm.jpg"),
    largeImg: require("../images/other/Earlier/21_Beneath_The_Surface_92x61cm.jpg"),
  },
];
